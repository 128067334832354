import React, {ReactNode, useRef, useState} from "react";
import Headroom from "react-headroom";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {p} from "@pg-design/helpers-css";
import {isEmpty} from "@pg-mono/nodash";

import {navigationHeight} from "./contstants/navigation_height";
import {LogoAndBurger} from "./LogoAndBurger";
import {INavElementStyle, NavigationElement} from "./NavigationElement";
import {NavigationElementWithDropdown} from "./NavigationElementWithDropdown";

export interface INavRightElement {
    title: string;
    url?: string;
    rel?: string;
    icon?: JSX.Element;
    subElements?: INavRightElement[];
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    count?: number;
    gridMin?: string;
    gridMax?: string;
    gridMaxWide?: string;
    target?: string;
    style?: INavElementStyle;
    hideOnMobile?: boolean;
    hideOnDesktop?: boolean;
}

export interface INavMiddleElement {
    title: string;
    icon: JSX.Element;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export interface INavLeftElement {
    title: string;
    url?: string;
    subElements?: INavLeftElement[];
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    target?: string;
    style?: INavElementStyle;
    hideOnMobile?: boolean;
    hideOnDesktop?: boolean;
}

export type NavLevelType = 0 | 1 | 2 | 3 | 4;

interface IProps {
    navLeftElements: INavLeftElement[];
    navMiddleElement?: INavMiddleElement | null;
    navMobileMiddleElements?: INavMiddleElement[];
    navRightElements?: INavRightElement[];
    langSwitcher?: ReactNode;
    logo: {
        imgUrl: string;
        url: string;
        alt: string;
        title: string;
    };
    arrows: {
        left: JSX.Element;
        right: JSX.Element;
    };
    backToUrl?: {
        url: string;
        text: string;
    };
    isMobile?: boolean;
    mobileBreakpoint?: number;
    isPrinting?: boolean;
    blendTopOffset?: string;
    onPin?: () => void;
    onUnpin?: () => void;
    onUnfix?: () => void;
}

export const Navigation = (props: IProps) => {
    const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [isBlendVisible, setBlendVisible] = useState(false);
    const [navState, setNavState] = useState<number[]>([]);
    const [navRightState, setNavRightState] = useState<number[]>([]);
    const navListRef = useRef<HTMLUListElement>(null);

    if (props.isPrinting) {
        return null;
    }

    const onBurgerClose = () => {
        setMobileMenuVisible(false);
        setNavState([]);
        setBlendVisible(false);
        const body = document.getElementsByTagName("body");
        body[0].classList.remove("ReactModal__Body--open");
    };

    const onBurgerOpen = () => {
        setMobileMenuVisible(true);
        setBlendVisible(true);
        const body = document.getElementsByTagName("body");
        body[0].classList.add("ReactModal__Body--open");
    };

    const listScrollToTop = () => {
        navListRef.current?.scrollIntoView();
    };

    const generateMobileMenuLabel = () => {
        if (isMobileMenuVisible && !isEmpty(navState)) {
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            switch (navState.length) {
                case 1:
                    return props.navLeftElements[navState[0]].title;
                case 2:
                    return props.navLeftElements[navState[0]].subElements![navState[1]].title;
                case 3:
                    return props.navLeftElements[navState[0]].subElements![navState[1]]!.subElements![navState[2]].title;
                case 4:
                    return props.navLeftElements[navState[0]].subElements![navState[1]].subElements![navState[2]]!.subElements![navState[3]].title;
            }
            /* eslint-enable @typescript-eslint/no-non-null-assertion */
        }

        if (isMobileMenuVisible && props.navRightElements && !isEmpty(navRightState)) {
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            switch (navRightState.length) {
                case 1:
                    return props.navRightElements[navRightState[0]].title;
                case 2:
                    return props.navRightElements[navRightState[0]].subElements![navRightState[1]].title;
                case 3:
                    return props.navRightElements[navRightState[0]].subElements![navRightState[1]]!.subElements![navRightState[2]].title;
                case 4:
                    return props.navRightElements[navRightState[0]].subElements![navRightState[1]].subElements![navRightState[2]]!.subElements![
                        navRightState[3]
                    ].title;
            }
            /* eslint-enable @typescript-eslint/no-non-null-assertion */
        }

        return "Menu";
    };

    const onBackClick = () => {
        if (!isEmpty(navState)) {
            setNavState(navState.slice(0, -1));
        }

        if (!isEmpty(navRightState)) {
            setNavRightState(navRightState.slice(0, -1));
        }
    };

    const logoAndBurgerProps = {
        logo: props.logo,
        arrows: {
            left: <div onClick={listScrollToTop}>{props.arrows.left}</div>
        },
        isOpen: isMobileMenuVisible,
        setOpen: onBurgerOpen,
        setClose: onBurgerClose,
        onBack: onBackClick,
        label: generateMobileMenuLabel(),
        navState: !isEmpty(navState) ? navState : navRightState,
        isMobile: props.isMobile
    };

    const renderMobileMiddleElement = () => (
        <MobileMiddleElementsWrapper mobileBreakpoint={props.mobileBreakpoint}>
            {props.navMiddleElement && (
                <MobileMiddleElementWrapper mobileBreakpoint={props.mobileBreakpoint} onClick={props.navMiddleElement.onClick}>
                    {props.navMiddleElement.icon}
                </MobileMiddleElementWrapper>
            )}
            {props.navMobileMiddleElements?.map((navMobileMiddleElement, index) => (
                <div key={`${index}_${navMobileMiddleElement.title}`} css={mobileMiddleElement} onClick={navMobileMiddleElement.onClick}>
                    {navMobileMiddleElement.icon}
                </div>
            ))}
        </MobileMiddleElementsWrapper>
    );

    const renderLogoAndBurger = () => {
        if (props.isMobile) {
            if (props.backToUrl && props.backToUrl?.url !== "") {
                return (
                    <div css={fixedNav}>
                        <LogoAndBurger
                            {...logoAndBurgerProps}
                            middleElement={renderMobileMiddleElement()}
                            backToUrl={props.backToUrl}
                            mobileBreakpoint={props.mobileBreakpoint}
                        />
                    </div>
                );
            }
            return (
                <Headroom css={headroomStyles} disableInlineStyles onPin={props.onPin} onUnpin={props.onUnpin} onUnfix={props.onUnfix}>
                    <LogoAndBurger {...logoAndBurgerProps} middleElement={renderMobileMiddleElement()} mobileBreakpoint={props.mobileBreakpoint} />
                </Headroom>
            );
        }
        return <LogoAndBurger {...logoAndBurgerProps} middleElement={renderMobileMiddleElement()} mobileBreakpoint={props.mobileBreakpoint} />;
    };

    return (
        <>
            <Blend isActive={isBlendVisible} onClick={onBurgerClose} topOffset={props.blendTopOffset} />

            <NavigationHolder isMobileMenuVisible={isMobileMenuVisible}>
                <GridFluidNav mobileBreakpoint={props.mobileBreakpoint}>
                    {renderLogoAndBurger()}

                    <NavHolder mobileBreakpoint={props.mobileBreakpoint}>
                        <Nav mobileBreakpoint={props.mobileBreakpoint} style={isMobileMenuVisible ? {display: "flex"} : {}}>
                            <NavList
                                ref={navListRef}
                                onClick={listScrollToTop}
                                navState={!isEmpty(navState) ? navState : navRightState}
                                isNavStateElementEmpty={false}
                                mobileBreakpoint={props.mobileBreakpoint}
                            >
                                <NavigationElementWithDropdown
                                    navElements={props.navLeftElements}
                                    setBlendVisible={setBlendVisible}
                                    arrows={props.arrows}
                                    setNavState={setNavState}
                                    navState={navState}
                                    isMobile={props.isMobile}
                                    mobileBreakpoint={props.mobileBreakpoint}
                                />
                                <div css={navLangWrap}>{!isMobileMenuVisible && props.langSwitcher && <>{props.langSwitcher}</>}</div>
                                {props.navRightElements && !isEmpty(props.navRightElements) && (
                                    <NavRightListElement mobileBreakpoint={props.mobileBreakpoint}>
                                        <NavRightHolder mobileBreakpoint={props.mobileBreakpoint}>
                                            {props.navMiddleElement && !props.isMobile && (
                                                <NavigationElement
                                                    title={props.navMiddleElement.title}
                                                    icon={props.navMiddleElement.icon}
                                                    onClick={props.navMiddleElement.onClick}
                                                    css={desktopMiddleElement(props.mobileBreakpoint)}
                                                    isMobile={props.isMobile}
                                                    hideTextOnMobile
                                                    mobileBreakpoint={props.mobileBreakpoint}
                                                />
                                            )}
                                            <NavigationElementWithDropdown
                                                navElements={props.navRightElements}
                                                setBlendVisible={setBlendVisible}
                                                arrows={props.arrows}
                                                setNavState={setNavRightState}
                                                navState={navRightState}
                                                isRightElement
                                                isMobile={props.isMobile}
                                                mobileBreakpoint={props.mobileBreakpoint}
                                            />
                                        </NavRightHolder>
                                    </NavRightListElement>
                                )}
                                {isMobileMenuVisible && props.langSwitcher && (
                                    <MobileNavLangWrap mobileBreakpoint={props.mobileBreakpoint}>{props.langSwitcher}</MobileNavLangWrap>
                                )}
                            </NavList>
                        </Nav>
                    </NavHolder>
                </GridFluidNav>
            </NavigationHolder>
        </>
    );
};

export interface IMobileBreakpoint {
    mobileBreakpoint?: number;
}

interface INavigationHolder extends IMobileBreakpoint {
    isMobileMenuVisible: boolean;
}

const NavigationHolder = styled.div<INavigationHolder>`
    width: 100%;
    background: #fff;
    height: ${navigationHeight}px;
    position: relative;
    z-index: ${(props) => (props.isMobileMenuVisible ? 2002 : 2001)};

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint : 1024)}px) {
        z-index: 2001;
    }

    @media print {
        display: none !important;
    }
`;

const GridFluidNav = styled.section<IMobileBreakpoint>`
    min-width: 320px;
    max-width: 1190px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint : 1024)}px) {
        padding-left: 3.2rem;
        padding-right: 3.2rem;
    }

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint : 1400)}px) {
        max-width: 1600px;
    }
`;

const MobileMiddleElementsWrapper = styled.div<IMobileBreakpoint>`
    position: absolute;
    right: 60px;
    display: flex;
    flex-direction: row;
    z-index: 1000;

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint : 1024)}px) {
        display: none;
    }
`;

const MobileMiddleElementWrapper = styled.div<IMobileBreakpoint>`
    height: ${navigationHeight}px;
    width: 50px;
    position: absolute;
    right: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint : 1024)}px) {
        display: none;
    }
`;

const mobileMiddleElement = css`
    height: 60px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const desktopMiddleElement = (mobileBreakpoint?: number) => css`
    display: none;

    @media (min-width: ${mobileBreakpoint ? mobileBreakpoint + 1 : 1024}px) {
        display: block;
    }
`;

const NavHolder = styled.div<IMobileBreakpoint>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint : 1024)}px) {
        justify-content: space-between;
    }
`;

const fadeInAnimation = (time: string) => css`
    animation: fade-in ${time} cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

interface IBlend extends IMobileBreakpoint {
    isActive: boolean;
    topOffset?: string;
}

const Blend = styled.div<IBlend>`
    ${({topOffset, isActive, mobileBreakpoint}) => css`
        background: rgba(0, 0, 0, 0.6);
        width: 100%;
        height: 100%;
        position: fixed;
        top: ${topOffset ? topOffset : "0"};
        right: 320px;
        z-index: 2000;
        display: ${isActive ? "block" : "none"};
        ${fadeInAnimation("0.3s")};

        @media (min-width: 320px) {
            right: 320px;
        }

        @media (min-width: 360px) {
            right: 360px;
        }

        @media (min-width: 375px) {
            right: 375px;
        }

        @media (min-width: 414px) {
            right: 414px;
        }

        @media (min-width: 500px) {
            right: 360px;
        }
        @media (min-width: ${mobileBreakpoint ? mobileBreakpoint : 1024}px) {
            right: 0;
        }
    `}
`;

const Nav = styled.nav<IMobileBreakpoint>`
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    @media (max-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint : 1024)}px) {
        display: none;
        flex-direction: column;
        justify-content: flex-start;
        background: #fff;
        height: calc(100% - ${navigationHeight}px);
        position: fixed;
        right: 0;
        top: ${navigationHeight}px;
        overflow-x: hidden;
        overflow-y: auto;
        width: 320px;
        z-index: 2001;
        ${fadeInAnimation("0.27s")};

        @media (min-width: 320px) {
            width: 320px;
        }

        @media (min-width: 360px) {
            width: 360px;
        }

        @media (min-width: 375px) {
            width: 375px;
        }

        @media (min-width: 414px) {
            width: 414px;
        }

        @media (min-width: 500px) {
            width: 360px;
        }
    }
`;

const move = (navState: number[], screenWidth: number) => {
    if (navState.length === 0) {
        return;
    }

    switch (navState.length) {
        case 1:
            return `-${screenWidth}px`;
        case 2:
            return `-${screenWidth * 2}px`;
        case 3:
            return `-${screenWidth * 3}px`;
        case 4:
            return `-${screenWidth * 3}px`;
        default:
            return "0";
    }
};

interface IMoveNavThemeProps extends IMobileBreakpoint {
    navState: number[];
    isNavStateElementEmpty: boolean;
}

const moveNav = (props: IMoveNavThemeProps) => css`
    @media (max-width: ${props.mobileBreakpoint ? props.mobileBreakpoint - 1 : 1023}px) {
        transition: transform 0.35s;

        @media (min-width: 320px) {
            transform: translateX(${move(props.navState, 320)});
        }

        @media (min-width: 360px) {
            transform: translateX(${move(props.navState, 360)});
        }

        @media (min-width: 375px) {
            transform: translateX(${move(props.navState, 375)});
        }

        @media (min-width: 414px) {
            transform: translateX(${move(props.navState, 414)});
        }

        @media (min-width: 500px) {
            transform: translateX(${move(props.navState, 360)});
        }
    }
`;

const NavList = styled.ul`
    ${moveNav};
    margin: 0;
    padding-left: 0;
    list-style: none;

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint + 1 : 1024)}px) {
        display: flex;
        align-items: center;
    }
`;

const navLangWrap = css`
    flex: 1;
    display: flex;
    justify-content: flex-end;
`;

const MobileNavLangWrap = styled.div<IMobileBreakpoint>`
    display: block;
    ${p(3, 5)};
`;

const NavRightListElement = styled.li<IMobileBreakpoint>`
    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint + 1 : 1024)}px) {
        li > a {
            padding-right: 0;
        }
    }
`;

const NavRightHolder = styled.ul<IMobileBreakpoint>`
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding-left: 0;
    list-style: none;

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint + 1 : 1024)}px) {
        flex-direction: row;
    }
`;

const headroomStyles = css`
    &.headroom-wrapper {
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        padding-left: 1.2rem;
    }

    .headroom {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background: #fff;
        height: ${navigationHeight}px;
        display: flex;
        justify-content: space-between;
    }

    .headroom--unfixed {
        position: relative;
        transform: translateY(0);
    }

    .headroom--scrolled {
        transition: transform 200ms ease-in-out;
    }

    .headroom--unpinned {
        padding-left: 1.2rem;
        position: fixed;
        z-index: 100;
        transform: translateY(-100%);
    }

    .headroom--pinned {
        padding-left: 1.2rem;
        position: fixed;
        z-index: 100;
        transform: translateY(0%);
    }
`;

const fixedNav = css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 2;
    height: 60px;
    padding: 0 2rem;
`;

import {Reducer} from "redux";

import {IRPStore} from "../../app/rp_reducer";
import {IOfferDetail} from "../../offer/detail/types/IOfferDetail";
import {ISmartAdActions} from "../actions/add_smart_offer_action";
import {IAdMeta} from "../types/IAdMeta";
import {ICreation} from "../types/ICreation";
import {IHeroCreation} from "../types/IHeroCreation";
import {ISurpriseCreation} from "../types/ISurpriseCreation";

export interface ISmartAdsStore {
    offers: ISmartAdOffer[];
}

export interface ISmartAdOffer extends IAdMeta {
    creationDetails: ICreation | ISurpriseCreation | IHeroCreation | null;
    // In case the "Creation" (object received from SmartAdServer) is "Surprise", we won't receive related offer id
    offerDetails: IOfferDetail | null;
    didLoadCreationDetails: boolean;
}

const initialState: ISmartAdsStore = {
    offers: []
};

export const smartAdsReducer: Reducer<ISmartAdsStore, ISmartAdActions> = (state, action) => {
    if (!state) {
        return initialState;
    }

    switch (action.type) {
        case "ADD_SMART_AD_OFFER":
            const foundDuplicate = state.offers.find((smartAdOffer) => smartAdOffer.tagId === action.payload.tagId);

            if (foundDuplicate) {
                return state;
            } else {
                return {
                    ...state,
                    offers: [...state.offers, action.payload]
                };
            }
        case "UPDATE_SMART_AD_OFFER":
            return {
                ...state,
                offers: state.offers.map((smartAdOffer) => {
                    if (smartAdOffer.tagId === action.payload.tagId) {
                        return {...smartAdOffer, ...action.payload};
                    }

                    return smartAdOffer;
                })
            };
        case "REMOVE_SMART_AD_OFFERS":
            return {
                ...state,
                offers: []
            };
        default:
            return state;
    }
};

export const selectSmartAdOffer = (placementId: string) => (state: IRPStore) =>
    state.smartAds.offers.find((smartAdOffer) => smartAdOffer.tagId === placementId);

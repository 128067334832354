import React, {CSSProperties} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {CountBadge} from "./CountBadge";
import {IMobileBreakpoint} from "./Navigation";

interface IProps {
    title: string;
    url?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    icon?: JSX.Element;
    rel?: string;
    count?: number;
    className?: string;
    target?: string;
    isMobile?: boolean;
    style?: INavElementStyle;
    hideOnMobile?: boolean;
    mobileBreakpoint?: number;
    hideTextOnMobile?: boolean;
}

export const NavigationElement = (props: IProps) => {
    return (
        <ElementHolder mobileBreakpoint={props.mobileBreakpoint} hideOnMobile={props.hideOnMobile}>
            <Element mobileBreakpoint={props.mobileBreakpoint} href={props.url} rel={props.rel} onClick={props.onClick} target={props.target}>
                {props.icon && <IconHolder isMobile={props.isMobile}>{props.icon}</IconHolder>}
                <NavigationElementText style={props.style} hideOnMobile={props.hideTextOnMobile} mobileBreakpoint={props.mobileBreakpoint}>
                    {props.title}
                </NavigationElementText>
                {props.count !== 0 && props.count && <CountBadge count={props.count} />}
            </Element>
        </ElementHolder>
    );
};

interface IElementHolder extends IMobileBreakpoint {
    hideOnMobile?: boolean;
}

const ElementHolder = styled.li<IElementHolder>`
    position: relative;
    display: flex;

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint + 1 : props.theme.breakpoints.numeric.md)}px) {
        display: inline-block;
        width: unset;
    }
    ${(props) =>
        props.hideOnMobile
            ? css`
                  @media (max-width: ${props.theme.breakpoints.md}) {
                      display: none;
                  }
              `
            : ""};
`;

const Element = styled.a<IMobileBreakpoint>`
    position: relative;
    font-size: 1.24rem;
    color: #23232d;
    padding: 2rem 2.5rem 2rem 4rem;
    height: 60px;
    cursor: pointer;
    width: 320px;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
        text-decoration: none;
        background: #fff;
        color: #23232d;
    }

    @media (min-width: 320px) {
        width: 320px;
    }

    @media (min-width: 360px) {
        width: 360px;
    }

    @media (min-width: 375px) {
        width: 375px;
    }

    @media (min-width: 414px) {
        width: 414px;
    }

    @media (min-width: 500px) {
        width: 360px;
    }

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint + 1 : 1024)}px) {
        width: unset;
        padding: 2rem 1.5rem;
        white-space: nowrap;
    }

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint + 1 : 1024)}px) {
        padding: 2rem;
    }
`;

interface StyledNavElementsProps {
    elementStyle?: INavElementStyle;
    hideOnMobile?: boolean;
    mobileBreakpoint?: number;
}

export interface INavElementStyle extends Pick<CSSProperties, "color" | "backgroundColor" | "fontWeight"> {}

export const NavigationElementText = styled.span<StyledNavElementsProps>`
    ${(props) =>
        props.elementStyle
            ? css`
                  ${props.elementStyle.color ? `color: ${props.elementStyle.color}` : ""};
                  ${props.elementStyle.backgroundColor ? `background-color: ${props.elementStyle.backgroundColor}` : ""};
                  ${props.elementStyle.fontWeight ? `font-weight: ${props.elementStyle.fontWeight}` : ""};
                  border-radius: 8px;
                  padding: 0 4px;
              `
            : ""};

    ${(props) =>
        props.hideOnMobile
            ? css`
                  display: block;

                  @media (min-width: ${props.mobileBreakpoint ? props.mobileBreakpoint + 1 : 1024}px) {
                      display: none;
                  }

                  @media (min-width: 1200px) {
                      display: block;
                  }
              `
            : ""};
`;

const IconHolder = styled.div<{isMobile?: boolean}>`
    display: flex;
    align-items: center;
    margin-right: ${(props) => (props.isMobile ? "2.6rem" : "1rem")};
`;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const offerListingSlice = createSlice({
    name: "offerListing",
    initialState: {
        navigationPinned: false
    },
    reducers: {
        setOfferListingNavigationPinned: (state, action: PayloadAction<boolean>) => {
            state.navigationPinned = action.payload;
        }
    }
});

export const {setOfferListingNavigationPinned} = offerListingSlice.actions;

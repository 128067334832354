import {areaFormat, pluralize, priceFormat} from "@pg-mono/string-utils";

import {IRegion} from "../../../meta_data/page_description/offer_list";
import {getFloorChoiceText} from "../../../real_estate/utils/get_floor_choice_text";
import {RegionType} from "../../../region/types/RegionType";
import {friendlyQueryToDescription} from "../../constants/house_filter";
import {getHouseFilterMetaText} from "../../list/helpers/sub_type/meta/get_house_filter_meta_text";
import {getFloorChoiceByOfferListSubType} from "../../list/helpers/sub_type/transform/get_floor_choice_by_offer_list_sub_type";
import {isOfferListSubTypeValidFloorChoice} from "../../list/helpers/sub_type/validator/is_offer_list_sub_type_valid_floor_choice";
import {isOfferListSubTypeValidHouseFilter} from "../../list/helpers/sub_type/validator/is_offer_list_sub_type_valid_house_filter";
import {OfferType} from "../../types/OfferType";

export interface ISearchPhrase {
    type?: OfferType;
    regionName?: string;
    offerName?: string;
    vendorName?: string;
    placeName?: string;
    sort?: string;
    price_0?: string;
    price_1?: string;
    area_0?: string;
    area_1?: string;
    rooms_0?: string;
    rooms_1?: string;
    is_luxury?: boolean;
    is_holiday?: boolean;
    price_lower_than_average?: boolean;
    construction_end_date?: string;
    regions?: IRegion[];
    offerListSubType?: string;
}

export interface ISearchPhraseOptions {
    rooms: {
        showSingleFilterAsNumber?: boolean;
    };
    offerListSubFilter?: string;
    isTitle?: boolean;
    isMetaDescription?: boolean;
    isSingleRoomFlat?: boolean;
}

const roomsVerbose = {
    1: "jednopokojowe",
    2: "dwupokojowe",
    3: "trzypokojowe",
    4: "czteropokojowe",
    5: "pięciopokojowe i więcej"
} as Record<number, string>;

const roomPlural = pluralize(["pokoju", "pokoi", "pokoi"]);

const regionsTypesWithForSalePhrase = [RegionType.CITY, RegionType.TOWN, RegionType.DISTRICT, RegionType.NEIGHBORHOOD];

const inRange = (val: number, a: number, b: number) => {
    let res = val;
    res = Math.max(a, res);
    res = Math.min(b, res);
    return res;
};

const getSingleFilterText = (value: number, options?: ISearchPhraseOptions["rooms"]) => {
    if (value === 1) {
        return roomsVerbose[1];
    }

    if (options?.showSingleFilterAsNumber) {
        return `${value} pokojowe`;
    }

    return roomsVerbose[value];
};
export const getRoomsNameString = (rooms_0: number, rooms_1: number, options?: ISearchPhraseOptions["rooms"]): string => {
    if (isFinite(rooms_0) && isFinite(rooms_1)) {
        if (rooms_0 === rooms_1) {
            const val = inRange(rooms_1, 1, 5);
            return getSingleFilterText(val, options);
        }
        // values differ
        let val0 = rooms_0;
        let val1 = rooms_1;
        val0 = Math.max(1, val0);
        val1 = Math.min(5, val1);
        if (val0 === 1 && val1 === 5) {
            return "";
        }
        if (val1 === 5) {
            return `od ${val0} ${roomPlural(val0)}`;
        }
        if (val0 === 1) {
            return `do ${val1} ${roomPlural(val1)}`;
        }
        return `od ${val0} do ${val1} ${roomPlural(val1)}`;
    }
    if (isFinite(rooms_0)) {
        const val = inRange(rooms_0, 1, 5);
        if (val === 1) {
            return "";
        }
        if (val === 5) {
            return getSingleFilterText(val, options);
        }
        return `od ${val} ${roomPlural(val)}`;
    }
    if (isFinite(rooms_1)) {
        const val = inRange(rooms_1, 1, 5);
        if (val === 1) {
            return getSingleFilterText(val, options);
        }
        if (val === 5) {
            return "";
        }
        return `do ${val} ${roomPlural(val)}`;
    }
    return "";
};

const castSearchData = (data: ISearchPhrase) => {
    const offerType = parseInt((data.type || "").toString(), 10);
    const area_0 = parseInt((data.area_0 || "").toString(), 10);
    const area_1 = parseInt((data.area_1 || "").toString(), 10);
    const rooms_0 = parseInt((data.rooms_0 || "").toString(), 10);
    const rooms_1 = parseInt((data.rooms_1 || "").toString(), 10);
    const price_0 = parseInt((data.price_0 || "").toString(), 10);
    const price_1 = parseInt((data.price_1 || "").toString(), 10);

    return {
        offerType,
        area_0,
        area_1,
        rooms_0,
        rooms_1,
        price_0,
        price_1
    };
};

const prepareSearchPhrase = (data: ISearchPhrase, options?: ISearchPhraseOptions) => {
    const urlParts: string[] = [];

    // We need an int for url building.
    const castedSearchData = castSearchData(data);

    switch (castedSearchData.offerType) {
        case OfferType.FLAT:
            if (data.is_luxury) {
                urlParts.push("apartamenty");
            } else {
                urlParts.push("mieszkania");
            }
            break;
        case OfferType.HOUSE:
            // house type filter
            if (options && options.offerListSubFilter && friendlyQueryToDescription[options.offerListSubFilter]) {
                urlParts.push(`domy ${friendlyQueryToDescription[options.offerListSubFilter]}`);
            } else {
                urlParts.push("domy");
            }
            break;
        case OfferType.COMMERCIAL:
            urlParts.push("lokale użytkowe");
            break;
        default:
            urlParts.push("nieruchomości");
    }

    urlParts.push("");

    // Price
    if (castedSearchData.price_0 || castedSearchData.price_1) {
        urlParts.push(`cena ${priceFormat([castedSearchData.price_0, castedSearchData.price_1])}`);
    }

    // Area
    if (castedSearchData.area_0 || castedSearchData.area_1) {
        urlParts.push(`powierzchnia ${areaFormat([castedSearchData.area_0, castedSearchData.area_1])}`);
    }

    // Rooms
    const roomsString = getRoomsNameString(castedSearchData.rooms_0, castedSearchData.rooms_1, options?.rooms);

    if ((!options?.rooms.showSingleFilterAsNumber && roomsString) || options?.isTitle || options?.isMetaDescription) {
        urlParts.push(roomsString);
    }

    return urlParts;
};

const combineRoomsNameAndOfferTypeName = (urlParts: string[], isTitle: boolean) => {
    // for flats that are single room we merge "jednopokojowe" and "mieszkania" into "kawalerki" for SEO reasons]
    const singlRoomFlatName = isTitle ? "Kawalerka" : "kawalerki";
    if (urlParts.includes("mieszkania") && urlParts.includes("jednopokojowe")) {
        return [singlRoomFlatName, ...urlParts.filter((part) => part !== "mieszkania" && part !== "jednopokojowe")];
    }
    return urlParts;
};
// Offer search friendly URL builder
export const offerSearchPhraseBuilder = (data: ISearchPhrase, options?: ISearchPhraseOptions): string => {
    const [type, filterName, ...urlParts] = combineRoomsNameAndOfferTypeName(prepareSearchPhrase(data, options), !!options?.isTitle);
    const locationName = (() => {
        if (data.regionName) {
            return ` ${data.regionName}`;
        }
        return "";
    })();
    /*
    locationName may be:
        -empty string
        -single locationa e.g. " Warszawa" or " mazowieckie" (city/county/voivodeship neme)
        -multiple location e.g "- Warszawa, mazowieckie - Wrocław, dolnośląskie"
     */
    const isMultiRegion = !!data.regions && data.regions.length > 1;
    const isCityOrDistrict = !!data.regions && data.regions.length > 0 && regionsTypesWithForSalePhrase.includes(data.regions[0].type);
    const removeDuplicateFromUrlParts = (value: string) => {
        return urlParts.reduce((acc, curr) => {
            if (value === curr) {
                return acc;
            }
            return acc;
        }, []);
    };

    if (data.offerListSubType && isOfferListSubTypeValidFloorChoice(data.offerListSubType)) {
        const floorChoice = getFloorChoiceByOfferListSubType(data.offerListSubType);
        const floorText = getFloorChoiceText(floorChoice);

        return `${type} ${floorText} ${locationName}`;
    }

    if (data.offerListSubType && isOfferListSubTypeValidHouseFilter(data.offerListSubType)) {
        const houseFilterText = getHouseFilterMetaText(data.offerListSubType);

        return `${type} ${houseFilterText} ${locationName}`;
    }

    if (data.is_luxury || data.price_lower_than_average || data.construction_end_date === "0") {
        return `${type}${filterName}${locationName} ${urlParts.join(", ")}`;
    }

    if (!isMultiRegion && isCityOrDistrict && (data.type === OfferType.FLAT || data.type === OfferType.HOUSE)) {
        const saleText = `${options?.isTitle && options.isSingleRoomFlat ? " " : " na "}sprzedaż`;
        if (options?.isSingleRoomFlat) {
            return `${type}${filterName}${options?.isMetaDescription ? "" : saleText}${locationName} ${urlParts.join(", ")}`;
        }

        return `${type}${saleText}${filterName}${locationName} ${urlParts.join(", ")}`;
    }

    if (!!options?.isTitle || options?.isMetaDescription) {
        if ((locationName && data.rooms_0) || data.rooms_1) {
            return `${type} ${urlParts.join(", ")}${locationName}`;
        }
        if (urlParts.length > 1) {
            return `${type} ${urlParts.join(", ")}${locationName}`;
        }
        return `${type}${urlParts.join(", ")}${locationName}`;
    }

    if (options?.rooms.showSingleFilterAsNumber) {
        const {rooms_0, rooms_1} = castSearchData(data);
        const roomsText = !options?.isSingleRoomFlat ? getRoomsNameString(rooms_0, rooms_1, options.rooms) : "";

        return `${type}${filterName}${roomsText}${locationName} ${removeDuplicateFromUrlParts(roomsText).join(", ")}`;
    }

    return `${type}${filterName}${locationName} ${urlParts.join(", ")}`;
};

export const offerMapSearchPhraseBuilder = (data: ISearchPhrase, options?: ISearchPhraseOptions): string => {
    const [type] = prepareSearchPhrase(data, options);

    return type;
};

import React, {useEffect, useState} from "react";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {isEmpty} from "@pg-mono/nodash";

import {IMobileBreakpoint, INavLeftElement, NavLevelType} from "./Navigation";
import {INavElementStyle, NavigationElementText} from "./NavigationElement";

interface IProps {
    dropdownElements: INavLeftElement[];
    navState: number[];
    setNavState: (update: (prev: number[]) => number[]) => void;
    title: string;
    url?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    itemIndex: number;
    arrow: JSX.Element;
    setBlendVisible: (isVisible: boolean) => void;
    icon?: JSX.Element;
    isRightElement?: boolean;
    mobileBreakpoint?: number;
    hidingBreakpoint?: number;
    style?: INavElementStyle;
}

export const DropdownListElement = (props: IProps) => {
    const [isMobile, setMobile] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        setMobile(props.mobileBreakpoint ? window.innerWidth < props.mobileBreakpoint : window.innerWidth < theme.breakpoints.numeric.md);
    }, []);

    const onClick = (
        e: React.MouseEvent<HTMLElement>,
        level: NavLevelType,
        idx: number,
        subElementsLength: number,
        onClick: ((e: React.MouseEvent<HTMLElement>) => void) | undefined
    ) => {
        if (isMobile && subElementsLength > 0) {
            if (props.navState.length < 3) {
                e.preventDefault();
            }
            props.setNavState((prev) => [...prev.slice(0, level - 1), idx]);
        }
        if (onClick) {
            onClick(e); // if you need to prevent default you should do it in callback function
            props.setBlendVisible(false);
        }
    };

    const mobileShowLevel = (level: NavLevelType, idx: number) => {
        if (isMobile && props.navState[level] === idx) {
            return {display: "block"};
        }
    };

    const onHoverToggle = (isActive: boolean) => {
        if (!isMobile) {
            props.setBlendVisible(isActive);
        }
    };

    return (
        <TopLevelListElement
            mobileBreakpoint={props.mobileBreakpoint}
            data-testid="navigation-container"
            onMouseEnter={() => onHoverToggle(true)}
            onMouseLeave={() => onHoverToggle(false)}
        >
            <TopLevelListElementContent
                mobileBreakpoint={props.mobileBreakpoint}
                onClick={(e) => onClick(e, 1, props.itemIndex, props.dropdownElements.length, props.onClick)}
                href={props.url}
            >
                <div css={iconAndTitleHolder}>
                    {props.icon && <div css={iconHolder}>{props.icon}</div>}
                    <NavigationElementText elementStyle={props.style}>{props.title}</NavigationElementText>
                </div>

                <TopLevelArrow mobileBreakpoint={props.mobileBreakpoint}>{!isEmpty(props.dropdownElements) && props.arrow}</TopLevelArrow>
            </TopLevelListElementContent>

            <FirstLevelHolder
                data-testid="navigation-first-level"
                isRightElement={props.isRightElement}
                mobileBreakpoint={props.mobileBreakpoint}
                className="first-level"
                style={mobileShowLevel(0, props.itemIndex)}
            >
                {props.dropdownElements.map((firstLevelItem, firstLevelItemIndex) => (
                    <FirstLevelListElement key={firstLevelItemIndex} mobileBreakpoint={props.mobileBreakpoint} data-testid="navigation-first-level-element">
                        <SubElement
                            mobileBreakpoint={props.mobileBreakpoint}
                            onClick={(e) =>
                                onClick(e, 2, firstLevelItemIndex, firstLevelItem.subElements ? firstLevelItem.subElements.length : 0, firstLevelItem.onClick)
                            }
                            level={1}
                            href={firstLevelItem.url}
                            style={firstLevelItem.style}
                            target={firstLevelItem.target}
                        >
                            {firstLevelItem.title}

                            <div>{!isEmpty(firstLevelItem.subElements) && props.arrow}</div>
                        </SubElement>

                        {!isEmpty(firstLevelItem.subElements) && (
                            <SubLevelHolder
                                mobileBreakpoint={props.mobileBreakpoint}
                                data-testid="navigation-second-level"
                                className="second-level"
                                style={mobileShowLevel(1, firstLevelItemIndex)}
                            >
                                {firstLevelItem.subElements?.map((secondaryLevelItem, secondaryLevelItemIndex) => (
                                    <SecondLevelListElement
                                        key={secondaryLevelItemIndex}
                                        mobileBreakpoint={props.mobileBreakpoint}
                                        data-testid="navigation-second-level-element"
                                        hideOnDesktop={secondaryLevelItem.hideOnDesktop}
                                    >
                                        <SubElement
                                            mobileBreakpoint={props.mobileBreakpoint}
                                            onClick={(e) =>
                                                onClick(
                                                    e,
                                                    3,
                                                    secondaryLevelItemIndex,
                                                    secondaryLevelItem.subElements ? secondaryLevelItem.subElements.length : 0,
                                                    secondaryLevelItem.onClick
                                                )
                                            }
                                            level={2}
                                            href={secondaryLevelItem.url}
                                            target={secondaryLevelItem.target}
                                        >
                                            {secondaryLevelItem.title}

                                            <div>{!isEmpty(secondaryLevelItem.subElements) && props.arrow}</div>
                                        </SubElement>

                                        {!isEmpty(secondaryLevelItem.subElements) && (
                                            <SubLevelHolder
                                                mobileBreakpoint={props.mobileBreakpoint}
                                                data-testid="navigation-third-level"
                                                className="third-level"
                                                style={mobileShowLevel(2, secondaryLevelItemIndex)}
                                            >
                                                {secondaryLevelItem.subElements?.map((thirdLevelListElement, thirdLevelItemIndex) => {
                                                    return (
                                                        <li key={thirdLevelItemIndex} data-testid="navigation-third-level-element">
                                                            <SubElement
                                                                mobileBreakpoint={props.mobileBreakpoint}
                                                                onClick={(e) =>
                                                                    onClick(
                                                                        e,
                                                                        4,
                                                                        thirdLevelItemIndex,
                                                                        thirdLevelListElement.subElements ? thirdLevelListElement.subElements.length : 0,
                                                                        thirdLevelListElement.onClick
                                                                    )
                                                                }
                                                                level={3}
                                                                href={thirdLevelListElement.url}
                                                                target={thirdLevelListElement.target}
                                                            >
                                                                {thirdLevelListElement.title}
                                                            </SubElement>
                                                        </li>
                                                    );
                                                })}
                                            </SubLevelHolder>
                                        )}
                                    </SecondLevelListElement>
                                ))}
                            </SubLevelHolder>
                        )}
                    </FirstLevelListElement>
                ))}
            </FirstLevelHolder>
        </TopLevelListElement>
    );
};

export interface IFirstLevelHolderProps extends IMobileBreakpoint {
    isRightElement?: boolean;
}

interface ISecondLevelElementProps extends IMobileBreakpoint {
    hideOnDesktop?: boolean;
}

// TOP LEVEL
const TopLevelListElement = styled.li<IMobileBreakpoint>`
    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint + 1 : 1024)}px) {
        display: inline-block;
        width: unset;
        position: relative;

        &:hover {
            background: #fff;

            .first-level {
                display: block;
            }
        }
    }
`;

const TopLevelListElementContent = styled.a<IMobileBreakpoint>`
    height: 60px;
    padding: 2rem 2.5rem 2rem 4rem;
    font-size: 1.24rem;
    color: #23232d;
    cursor: pointer;
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &:focus {
        text-decoration: none;
        color: #23232d;
    }

    @media (min-width: 320px) {
        width: 320px;
    }

    @media (min-width: 360px) {
        width: 360px;
    }

    @media (min-width: 375px) {
        width: 375px;
    }

    @media (min-width: 414px) {
        width: 414px;
    }

    @media (min-width: 500px) {
        width: 360px;
    }

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint + 1 : 1024)}px) {
        width: unset;
        padding: 2rem;
        white-space: nowrap;
    }

    @media (min-width: 1400px) {
        padding: 2rem 3rem;
    }
`;

const TopLevelArrow = styled.div<IMobileBreakpoint>`
    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint + 1 : 1024)}px) {
        display: none;
    }
`;

// FIRST LEVEL
const FirstLevelHolder = styled.ul<IFirstLevelHolderProps>`
    display: none;
    position: absolute;
    background: #fff;
    list-style: none;
    padding: 0;
    margin: 0;
    top: 0;
    right: -320px;
    height: 100%;
    z-index: 2001;

    @media (min-width: 320px) {
        right: -320px;
    }

    @media (min-width: 360px) {
        right: -360px;
    }

    @media (min-width: 375px) {
        right: -375px;
    }

    @media (min-width: 414px) {
        right: -414px;
    }

    @media (min-width: 500px) {
        right: -360px;
    }

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint + 1 : 1024)}px) {
        top: 60px;
        left: 0;
        height: unset;
        width: 271px;
        padding: 0 0 1rem 0;
        ${(props) =>
            props.isRightElement &&
            css`
                left: initial;
                right: -30px;
            `};
`;

//OTHER LEVELS
const SubLevelHolder = styled.ul<IMobileBreakpoint>`
    display: none;
    position: absolute;
    right: -320px;
    top: 0;
    padding: 0;
    background: #fff;
    list-style: none;

    @media (min-width: 320px) {
        right: -320px;
    }

    @media (min-width: 360px) {
        right: -360px;
    }

    @media (min-width: 375px) {
        right: -375px;
    }

    @media (min-width: 414px) {
        right: -414px;
    }

    @media (min-width: 500px) {
        right: -360px;
    }

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint : 1024)}px) {
        right: -271px;
        left: unset;
        padding: 0 0 1rem 0;
        min-height: 100%;
    }
`;

interface ISubElement extends IMobileBreakpoint {
    level: 1 | 2 | 3;
}

const levelStyle = (props: ISubElement) => css`
    height: 50px;
    background: #fff;
    font-size: 1.24rem;
    &:hover {
        background: #fafaf5;
    }

    @media (min-width: ${props.mobileBreakpoint ? props.mobileBreakpoint : 1024}px) {
        background: ${(props.level === 1 && "#fff") || (props.level === 2 && "#f9f9f9") || (props.level === 3 && "#ededee")};
        font-size: ${(props.level === 1 && "1.244rem") || (props.level > 1 && "1.106rem")};
        height: ${(props.level === 1 && "6rem") || (props.level === 2 && "3rem") || (props.level === 3 && "3rem")};
    }
`;

const SubElement = styled.a`
    ${levelStyle};
    color: #23232d;
    padding: 2rem 2.5rem 2rem 4rem;
    background: #fff;
    width: 320px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &:focus {
        text-decoration: none;
        color: #23232d;
    }

    @media (min-width: 320px) {
        width: 320px;
    }

    @media (min-width: 360px) {
        width: 360px;
    }

    @media (min-width: 375px) {
        width: 375px;
    }

    @media (min-width: 414px) {
        width: 414px;
    }

    @media (min-width: 500px) {
        width: 360px;
    }

    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint : 1024)}px) {
        width: 271px;
        padding: 1.5rem 2rem;
    }
`;

const FirstLevelListElement = styled.li<IMobileBreakpoint>`
    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint : 1024)}px) {
        &:hover {
            .second-level {
                display: block;
                background: #f9f9f9;
            }
        }
    }
`;

const SecondLevelListElement = styled.li<ISecondLevelElementProps>`
    @media (min-width: ${(props) => (props.mobileBreakpoint ? props.mobileBreakpoint : 1024)}px) {
        height: 3rem;
        display: ${(props) => (props.hideOnDesktop ? "none" : "block")};
        &:hover {
            .third-level {
                display: block;
                height: 100%;
                background: #ededee;
            }
        }
    }
`;

const iconAndTitleHolder = css`
    display: flex;
    align-items: center;
`;

const iconHolder = css`
    margin-right: 15px;
    display: flex;
    align-items: center;
`;
